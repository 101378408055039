import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInstagram } from "@fortawesome/free-brands-svg-icons";

function Instagram() {
  return (
    <div className="instagram-button">
      <a href="https://www.instagram.com/_newlook.official?igsh=MWs1NjcydTJoMnNtYg==">
        <Button className="connect-button">
          Connect With Us{" "}
          <FontAwesomeIcon
            style={{ fontSize: "25px", color: "#f7f7f7", marginLeft: "10px" }}
            icon={faSquareInstagram}
          />
        </Button>
      </a>
    </div>
  );
}

export default Instagram;
