import React, { useContext, useEffect, useState } from 'react';
import '../sticky/stickybottom.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCartShopping, faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';
import { SearchContext } from '../../context/Searchcontext';
import "../Header/Header.scss";
import { Modal } from 'react-bootstrap';
import Logo from "../../Assets/Logo.png";
import { Link } from 'react-router-dom';

const StickyBottom = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { setSearchResults } = useContext(SearchContext);
  const [favoriteCount, setFavoriteCount] = useState(() => {
    return JSON.parse(localStorage.getItem("favorites"))?.length || 0;
  });
  const [cartCount, setCartCount] = useState(() => {
    return JSON.parse(localStorage.getItem("cart"))?.length || 0;
  });
  const [discount, setDiscount] = useState([]);

  const fetchBanner = async () => {
    try {
      const response = await axios.get("https://lunarsenterprises.com:3008/newlook/list-banner");
      setDiscount(response.data.offer);
      console.log(response.data.offer);
    } catch (error) {
      console.error("Error fetching banner:", error);
    }
  };

  useEffect(() => {
    fetchBanner();
    const handleStorageChange = () => {
      setFavoriteCount(JSON.parse(localStorage.getItem("favorites"))?.length || 0);
      setCartCount(JSON.parse(localStorage.getItem("cart"))?.length || 0);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    if (isSearchVisible) {
      document.querySelector(".search-input").focus();
    }
  };

  const handleSearch = async (e) => {
    const searchText = e.target.value;
    if (searchText.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      console.log(searchText);

      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-product",
        { search: searchText }
      );
      setSearchResults(response.data.data);
      console.log(response);
    } catch (error) {
      console.error("An error occurred while searching:", error);
    }
  };

  return (
    <>
      <div className="sticky-bottom">
        <div className="container">
          <div className='d-flex justify-content-between'>
           
            <img src={Logo} className="logostyle" alt="logo" />
          
            {/* <div className="sicky" onClick={toggleSearch}>
              <FontAwesomeIcon icon={faMagnifyingGlass} className="sideicon" />
            </div> */}
            {/* <div className="sicky">
              <FontAwesomeIcon icon={faUser} className="sideicon" />
            </div> */}
            {/* <div className="sicky">
              <FontAwesomeIcon icon={faHeart} className="sideicon" />
              {favoriteCount > 0 && (
                <span className="badgestyle">{favoriteCount}</span>
              )}
            </div> */}
            <div className="sicky">
            <Link to="/cart">
              <FontAwesomeIcon icon={faCartShopping} className="sideicon" /></Link>
              {cartCount > 0 && (
                <span className="badgestylecart">{cartCount}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={isSearchVisible} onHide={toggleSearch} size="xl">
        <div className="modalmain">
          <input
            type="text"
            placeholder="Search..."
            className="form-control inputcontrol search-input"
            onChange={handleSearch}
          />
          <div className="searchcentre">
        
            <FontAwesomeIcon icon={faTimes} onClick={toggleSearch} />
          
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StickyBottom;
