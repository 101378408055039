import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create the context
const BannerContext = createContext();

// Create a provider component
const BannerProvider = ({ children }) => {
  const [banner, setBanner] = useState(null);
//   const [discount, setDiscount] = useState(null);

  const fetchBanner = async () => {
    try {
      const response = await axios.get("https://lunarsenterprises.com:3008/newlook/list-banner");
      setBanner(response.data.banner || []); // Ensure banner is an array
    //   setDiscount(response.data.offer.o_description);
    //   console.log(discount)
    } catch (error) {
      console.error("Error fetching banner:", error);
    }
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  return (
    <BannerContext.Provider value={{ banner }}>
      {children}
    </BannerContext.Provider>
  );
};

export { BannerContext, BannerProvider };
