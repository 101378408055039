import React, { useState, useEffect } from 'react';
import './review.css';
import { Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faReplyAll } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function Reviews() {
  const [commentlist, setcommentlist] = useState([]);

  // Fetch the comments list when the component is mounted
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.post(
          'https://lunarsenterprises.com:3008/newlook/list-comment'
        );
        setcommentlist(response.data.list);
        console.log('Commentshow.data', response.data);
      } catch (error) {
        console.error('Error fetching comments', error);
      }
    };

    fetchComments();
  }, []); // Empty dependency array to run only on mount

  return (
    <div className='container review-box'>
      <h4 className='review-heading'>Reviews</h4>
      <hr />
      {/* <Tabs defaultActiveKey="Review" className="custom-tab-content">
        <Tab eventKey="Review" title="Review"> */}
          <div>
            <div className="comment-section">
              <ul>
                {commentlist?.map((comment, index) => (
                  <li key={index}>
                    <div className="userside">
                      <FontAwesomeIcon icon={faUser} />
                      <h6 className="user">User:</h6>
                    </div>
                    <div className="comment">{comment.u_comment}</div>
                    <li key={index}>
                      <div className="adminside">
                        <FontAwesomeIcon
                          style={{ marginLeft: '15px' }}
                          icon={faReplyAll}
                        />
                        <h6 className="user-reply">Reply:</h6>
                      </div>
                      <div className="admin-comment">{comment.a_comment}</div>
                    </li>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        {/* </Tab>
      </Tabs> */}
    </div>
  );
}

export default Reviews;
