import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faUser,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Selected.scss";
import ReactImageMagnify from "react-image-magnify";
import { Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import "react-medium-image-zoom/dist/styles.css";
import nopreview from "../../Assets/nopreview.jpg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Imagemain from "../../Assets/noimage.jpg";

const Selected = () => {
  const navigate = useNavigate();
  const { p_id } = useParams();
  console.log(p_id, "dududhu");

  const [count, setCount] = useState(1);
  const [selectedColor, setSelectedColor] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [final, setFinal] = useState(0);

  // Total price including quantity and discount
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const [Viewapi, setViewapi] = useState([]);
  const [activeColor, setActiveColor] = useState(null);
  const [productName, setProductName] = useState(null);
  const [description, setDescription] = useState("");

  const [productSizes, setProductSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [nullImage, setNullImage] = useState("");
  const [color, setColor] = useState("");
  const [stock, setStock] = useState(0);
  const [availableStock, setAvailableStock] = useState(0); 
  //=============== 
  const [details, setDetails] = useState(() => {
    const productDetails = localStorage.getItem("productData");
    console.log("Initial product details from localStorage:", productDetails);
    return productDetails ? JSON.parse(productDetails) : [];
  });
  
  // Save to localStorage whenever details change
  // useEffect(() => {
  //   if (details.length > 0) {
  //     localStorage.setItem("cart", JSON.stringify(details));
  //   }
  // }, [details]);
  
  // console.log(details);
  // ============
  


  useEffect(() => {
    const Viewdata = async () => {
      try {
        const header = { p_id: p_id };
        const response = await axios.post(
          `https://lunarsenterprises.com:3008/newlook/view-product`,
          header
        );

        const productData = response.data.list[0];
        console.log(productData, "productDataproductData");

        if (productData && Array.isArray(productData.image)) {
          setViewapi(productData.image);

          setProductSizes(productData.ProductSize); // Ensure productData.image is an array
          setProductPrice(productData.price || productData.price);
          setMainImage(
            productData.image.length > 0
              ? `https://lunarsenterprises.com:3008/${productData.image[0]?.image.replace(
                  /\\/g,
                  "/"
                )}`
              : Imagemain
          );
          setStock(productData.stock);
          setAvailableStock(productData.stock || 0);

          // Find the image with color === null
          const nullImageData = productData.image.find(
            (item) => item.color === null
          );

          // If found, set the image URL
          if (nullImageData) {
            setNullImage(
              `https://lunarsenterprises.com:3008/${nullImageData.image.replace(
                /\\/g,
                "/"
              )}`
            );
          }
          const colors = productData.image.find(
            (items) => items.color === null
          );
          if (colors) {
            setColor(colors.color);
          }

          setProductName(productData.name);
          setSelectedColor(productData.image[0]?.color || "Default Color");
          setActiveColor(productData.image[0]?.color || "Default Color");
          setDescription(
            productData.description || "No description available."
          );
          setDiscount(productData.discount_price);

          // Initial calculation for total price and total discount
          setTotalPrice(productData.price);
          setFinal(productData.discount)
          setTotalDiscount(productData.discount_price);
          console.log(Viewapi, "color");
          console.log(nullImage);
        } else {
          console.error("No product images available.");
          setViewapi([]); // Default to an empty array if no images
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
    console.log(productSizes, "productSizes");

    Viewdata();
    CommentList();
  }, [p_id]);

  // Calculate total price and discount based on count
  useEffect(() => {
    // Ensure discount is calculated correctly
    if (count >= 1) {
      const priceAfterDiscount = productPrice - discount;
      setTotalPrice(productPrice * count); // Without discount
      setTotalDiscount((productPrice - priceAfterDiscount) * count); // With discount
    }
  }, [count, productPrice, discount]);

  const [colorid, setcolorid] = useState();

  const handleImageClick = (img) => {
    setMainImage(`https://lunarsenterprises.com:3008/${img.image}`);
    setSelectedColor(img.color);
    setActiveColor(img.color);
    setcolorid(img.color_id);
  };
  console.log(colorid, "hhhhh");
  console.log(nullImage);
  console.log(color);

  const Increment = () => {
    if (count < availableStock) {
      setCount(count + 1);
    }
  };
  
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  
  // List comment logic here (same as in your code)
  // list comment
  const [commentlist, setcommentlist] = useState([]);

  const CommentList = async () => {
    try {
      const Commentshow = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-comment"
      );
      setcommentlist(Commentshow.data.list);
      console.log("Commentshow.data", Commentshow.data);
    } catch (error) {}
  };

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/add-comment",
        { comment: newComment }
      );
      if (response.data.result) {
        toast.success("Comment submitted successfully!");
        setNewComment("");
        CommentList(); // Reload comments
      } else {
        toast.error("Failed to submit comment.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };
  // my test
  const moveToCart = () => {
    if (count > stock) {
      toast.error("Quantity exceeds available stock!");
      return; // Don't proceed if not enough stock
    }
    const productDatas = {
      id: p_id, // Static ID
      name: productName,
      image: mainImage,
      quantity: count,
      price: productPrice,
      totalPrice: totalPrice,
      color: selectedColor,
      totalDiscount: totalDiscount,
      imagePreview: imagePreview,
      selectedSize: selectedSize,
      colorid: colorid,
    };
     
  
    


    let existingProducts = localStorage.getItem("products");

    if (existingProducts) {
      let existProductsArr = JSON.parse(existingProducts);

      existProductsArr = existProductsArr.filter((el) => el.id !== p_id);

      existProductsArr.push(productDatas);

      localStorage.setItem("cart", JSON.stringify(existProductsArr));
      localStorage.setItem(
        "cartCount",
        JSON.stringify(existProductsArr.length)
      );
      window.dispatchEvent(new Event("storage"));
    } else {
      localStorage.setItem("cart", JSON.stringify([productDatas]));
      localStorage.setItem("cartCount", JSON.stringify(1));
      window.dispatchEvent(new Event("storage"));
    }

  };
  const moveToCartout = () => {
    const productDatas = {
      id: p_id, // Static ID
      name: productName,
      image: mainImage,
      quantity: count,
      price: productPrice,
      totalPrice: totalPrice,
      color: selectedColor,
      colorid: colorid,
      totalDiscount: totalDiscount,
      imagePreview: imagePreview,
      selectedSize: selectedSize,
      colorid: colorid,
    };

    let existingProducts = localStorage.getItem("products");

    if (existingProducts) {
      let existProductsArr = JSON.parse(existingProducts);

      existProductsArr = existProductsArr.filter((el) => el.id !== 1);

      existProductsArr.push(productDatas);

      localStorage.setItem("products", JSON.stringify(existProductsArr));
      localStorage.setItem(
        "cartCount",
        JSON.stringify(existProductsArr.length)
      );
      window.dispatchEvent(new Event("storage"));
    } else {
      localStorage.setItem("products", JSON.stringify([productDatas]));
      localStorage.setItem("cartCount", JSON.stringify(1));
      window.dispatchEvent(new Event("storage"));
    }
    navigate("/checkout", {
      state: {
        quantity: count,
        totalAmounts: totalPrice,
        image: mainImage,
        name: productName,
        id: p_id,
        product_Price: productPrice,
        color: selectedColor,
        totalDiscount: totalDiscount,
        imagePreview: imagePreview,
        selectedSize: selectedSize,
        colorid: colorid,
      },
    });
  };
  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setImageFile(file); // Update state with the selected file
      const previewUrl = URL.createObjectURL(file); // Create a preview URL
      setImagePreview(previewUrl); // Set the preview URL
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="container product-display mb-5">
        <div className="selected-product">
          <div className="product-images">
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: productName,
                  isFluidWidth: true,
                  src: nullImage || Image,
                },
                largeImage: {
                  src: nullImage,
                  width: 1200,
                  height: 1800,
                },
              }}
            />
            <div className="sub-images">
              {Array.isArray(Viewapi) && Viewapi.length > 0 ? (
                Viewapi?.map((img, index) => (
                  <div key={index}>
                    <img
                      src={
                        img.image
                          ? `https://lunarsenterprises.com:3008/${img.image}`
                          : Imagemain
                      }
                      alt={`Color: ${img.color}`}
                      className="sub-image"
                      onClick={() => handleImageClick(img)}
                      style={{
                        border:
                          activeColor === img.color ? "2px solid red" : "",
                      }}
                      onError={(e) => (e.target.src = Imagemain)}
                    />
                  </div>
                ))
              ) : (
                <p>No images available</p> // Display a message when there are no images
              )}
            </div>
          </div>
          <div className="product-details">
            <div>
              <div className=" d-flex">
              <h1>{productName}</h1>
              <h4 className="final-discount">-{final}%</h4>
              </div>
              <h6 style={{color:'brown'}}>(Stock Available: {stock - count >= 0 ? stock - count : 0})</h6>

            </div>
            

            <div className="selected-product-price">
              <del className="selected-price">
                <span>Rs</span>
                {totalPrice} {/* Price without discount */}
              </del>
              <div className="selected-discounted-price">
                <span>Rs</span>
                {totalDiscount} {/* Price with discount */}
              </div>
            </div>
            <div className="color-selection">
              <h6>Colors</h6>
              <div className="colors">
                {Viewapi?.filter((img) => img.color !== null).map(
                  (img, index) => (
                    <div
                      key={index}
                      className="color-box"
                      onClick={() => handleImageClick(img)}
                      style={{
                        cursor: "pointer",
                        borderColor:
                          activeColor === img.color ? "red" : "black",
                      }}
                    >
                      {img.color}
                    </div>
                  )
                )}
              </div>
            </div>
            <div>
              <div className="color-selection">
                <h6>Select Product Size</h6>

                <InputGroup>
                  <Form.Select onChange={handleSizeChange} value={selectedSize}>
                    <option value="">Select a size</option>
                    {productSizes.map((size) => (
                      <option key={size.s_id} value={size.s_id}>
                        {size.s_name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>

                {selectedSize && <p>Selected Size: {selectedSize}</p>}
              </div>
            </div>
            <p>Quantity</p>
            <div className="counting-box">
              <button onClick={decrement}>
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <div className="count">{count}</div>
              <button onClick={Increment}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>

            <div className="image-upload">
              <h6>Upload an Image</h6>
              <InputGroup>
                <Form.Control
                  type="file"
                  accept="image/*" // Accept only image files
                  onChange={handleImageChange}
                />
              </InputGroup>

              {imagePreview && (
                <div className="image-preview" style={{ marginTop: "20px" }}>
                  <h6>Image Preview:</h6>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "300px",
                      maxHeight: "300px",
                      borderRadius: "5px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </div>
              )}
            </div>
            <button className="addtocart" onClick={moveToCart}>
              Add to Cart
            </button>
            <button className="addtocart" onClick={moveToCartout}>
              Buy Now
            </button>
          </div>
        </div>
        <div className="container describ">
          <Tabs
            defaultActiveKey="describe"
            id="product-tabs"
            className="custom-tabs"
          >
            <Tab
              eventKey="describe"
              title="Description"
              className="custom-tab-content"
            >
              <div>
                <ul>
                  {description.split(",").map((item, index) => (
                    <li key={index}>{item.trim()}</li>
                  ))}
                </ul>
              </div>
            </Tab>
            {/* Comment section logic (same as your existing code) */}
            <Tab
              eventKey="Comments"
              title="Comments"
              className="custom-tab-content"
            >
              <div>
                <div className="comment-section">
                  <form onSubmit={handleCommentSubmit}>
                    <textarea
                      value={newComment}
                      onChange={handleCommentChange}
                      placeholder="Write a comment..."
                    ></textarea>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
            </Tab>

            {/* 3rd tab */}
            <Tab
              eventKey="Review"
              title="Review"
              className="custom-tab-content"
            >
              <div>
                <div className="comment-section">
                  <ul>
                    {commentlist?.map((comment, index) => (
                      <li key={index}>
                        <div className="userside">
                          <FontAwesomeIcon icon={faUser} />
                          <h6 className="user">User:</h6>
                        </div>
                        <div className="comment">{comment.u_comment}</div>
                        <li key={index}>
                          <div className="adminside">
                            <FontAwesomeIcon
                              style={{ marginLeft: "15px" }}
                              icon={faReplyAll}
                            />
                            <h6 className="user-reply">Reply:</h6>
                          </div>
                          <div className="admin-comment">
                            {comment.a_comment}
                          </div>
                        </li>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Selected;
