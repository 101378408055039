import React, { useState, useEffect, useContext, useRef } from "react";
import { SearchContext } from "../../context/Searchcontext";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Caraosel from "../../components/Caraosel/Carausel";
import "./Home.scss";
import Whatsapp from "../../components/Whatsapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageMagnifier from "../../components/ImageMagnifier";
import blacksec from "../../Assets/A4 Black Second Image.jpg";
import Image from "../../Assets/noimage.jpg";
import { Button } from "react-bootstrap";
import { faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
import Instagram from "../../components/Terms/Instagram/Instagram";
import FullPageSpinner from "./pagespinner";

// import instagram from '../../Assets/instagram.png';

const Home = () => {
  const splideRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const goToSlide = (index) => {
    if (splideRef.current) {
      splideRef.current.go(index);
      setActiveIndex(index);
    }
  };
const [loading,setloading] =useState (true)
  const [posts, setPosts] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [cart, setCart] = useState([]);
  const [Premiumlist, setPremiumList] = useState([]);
  // highlights
  const [highlights, setHighlights] = useState([]);

  const [otherProducts, setOtherProducts] = useState([]);
  const navigate = useNavigate();
  const { searchResults } = useContext(SearchContext);

  useEffect(() => {
    const savedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setFavorites(savedFavorites);
    setCart(savedCart);
  }, []);

  
    const fetchHighlights = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3008/newlook/list-highlights"
        );
        setHighlights(response.data.list);
        console.log(response.data.list, "uguguyu");
      } catch (error) {
        console.log("Error fetching the highlights:", error);
      }
    };


  console.log(highlights, "uguguyu");

  const displayedPosts =
    searchResults && searchResults.length > 0 ? searchResults : posts;

  const handleFavoriteClick = (product) => {
    let updatedFavorites = [...favorites];
    const isFavorite = favorites.some((fav) => fav.p_id === product.p_id);
    if (isFavorite) {
      updatedFavorites = updatedFavorites.filter(
        (fav) => fav.p_id !== product.p_id
      );
      toast.info("Product removed from favorites");
    } else {
      updatedFavorites.push(product);
      toast.success("Product added to favorites");
    }

    setFavorites(updatedFavorites);
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    window.dispatchEvent(new Event("storage"));
  };

  const handleCartClick = (product) => {
    let updatedCart = [...cart];
    const isInCart = cart.some((item) => item.p_id === product.p_id);
    if (isInCart) {
      updatedCart = updatedCart.filter((item) => item.p_id !== product.p_id);
      toast.info("Product removed from cart");
    } else {
      updatedCart.push({ ...product, quantity: 1 });
      toast.success("Product added to cart");
    }
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    window.dispatchEvent(new Event("storage"));
  };

  useEffect(() => {
    ListPremium();
    ListProduct();
    fetchHighlights();


    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, []);
 
  // 
  

  const ListPremium = async () => {
    try {
      const Framename = { size: 12 };
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-products",
        Framename
      );
      if (response.data && response.data.result) {
        const products = response.data.list || [];
        setPremiumList(products);

        console.log(Premiumlist, "Premiumlist");

        // setNewArrivals(
        //   products.filter((product) => product.new_arrival_status === 1)
        // );
        // setOtherProducts(
        //   products.filter((product) => product.new_arrival_status === 0)
        // );
      }
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  };

  const [allproducts, setallproducts] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);

  const ListProduct = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-products"
      );
      if (response.data && response.data.result) {
        const products = response.data.list || [];
        setallproducts(products);

        setNewArrivals(response.data.newarrivals);
      }

      console.log(response, "products");
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  };

  const productDetails = (p_id) => {
    navigate(`/product/${p_id}`);
  };

  const defaultImage = blacksec;

  const highlightsData = [
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
    { imageUrl: "https://cheetah.cherishx.com/uploads/1623419132_large.jpg" },
  ];

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Whatsapp />
      <ToastContainer />

      <div className="container">
  <div className="">
    {highlights?.length > 0 ? (
      <Splide
        options={{
          type: "loop",
          perPage: 6,
          perMove: 1,
          pagination: false,
          breakpoints: {
            600: {
              perPage: 2,
            },
            767: {
              perPage: 3,
            },
            480: {
              perPage: 2,
            },
          },
        }}
      >
        {highlights.map((highlight, index) => (
          <SplideSlide key={index}>
            <div className="highlight-item">
              <img
                className="highlight-image"
                src={`https://lunarsenterprises.com:3008/${highlight.h_image}`}
              />
            </div>
          </SplideSlide>
        ))}
      </Splide>
    ) : (
      <p></p> 
    )}
  </div>
</div>


      <div className="">
        <Caraosel />
      </div>
      <div className="container contact-status">
        <div className="row shadow-box d-flex align-items-center">
          <div className="col-md-6 d-flex justify-content-center">
            <Button className="reviews-button" variant="primary" href="/review">
              REVIEW
            </Button>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <Instagram />
          </div>
        </div>
      </div>
      <div className="">
        <h4 className="container mt-3 mb-3 heading">Exculsive Frames</h4>
        <div className=" container products ">
          <div
            className=" product-displays container parent-container"
            style={{ position: "relative" }}
          >
            <Splide
              options={{
                perPage: 4,
                rewind: true,
                autoplay: true,
                interval: 6000,
                pagination: false,
                gap: "1rem",
                breakpoints: {
                  1200: {
                    perPage: 3,
                    gap: "1rem",
                  },
                  768: {
                    perPage: 2,
                    gap: "0.5rem",
                  },
                  480: {
                    perPage: 2,
                    gap: "0.25rem",
                  },
                },
              }}
              aria-label="customList"
              ref={splideRef}
              onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
            >
              {allproducts.length > 0 ? (
                allproducts.map((post) => (
                  <SplideSlide key={post.p_id}>
                    <div
                      className="product-card"
                      onClick={() => productDetails(post.p_id)}
                    >
                      <div className="product-image-container">
                        <img
                          src={
                            post.image && post.image.length > 0
                              ? `https://lunarsenterprises.com:3008/${post.image[0]?.image}`
                              : Image
                          }
                          alt={post.name}
                          className="default"
                          style={{ borderRadius: 10 }}
                        />
                        <img
                          src={
                            post.image && post.image.length > 0
                              ? `https://lunarsenterprises.com:3008/${post.image[1]?.image}`
                              : Image
                          }
                          alt={post.name}
                          className="hover"
                          style={{ borderRadius: 20 }}
                        />
                        <div className="sale-containerdiscount">
                          <p>-{post.discount}%</p>
                        </div>
                        {post.stock === 0 ? (
                          <div className="sale-containersoldout">
                            <p>Sold out</p>
                          </div>
                        ) : null}
                      </div>
                      <div className="namemain">
                        <div className="product-name">
                          <h6>{post.name}</h6>
                        </div>
                        <div className="product-price-details">
                          <del className="price ">
                            <span>Rs</span>
                            {post.price}
                          </del>
                          <div
                            className="discounted-price"
                            style={{ color: "red" }}
                          >
                            <span>Rs</span>
                            {post.discount_price}
                          </div>
                        </div>
                        <div
                          className={`product-namecart cartaddbox bg-danger ${
                            cart.some((item) => item.p_id === post.p_id)
                              ? "selected"
                              : "unselected"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCartClick(post);
                          }}
                        >
                          <p className="centerrr">
                            {cart.some((item) => item.p_id === post.p_id)
                              ? "Remove from Cart"
                              : "Add to Cart"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              ) : (
                <div>
                  <h1>Products not Availiable in Exculsive</h1>
                </div>
              )}
            </Splide>
          </div>
        </div>
      </div>

      <div className="">
        <h3 className="container mt-3 mb-3 heading">New Arrivals </h3>
        <div className=" container products ">
          <div
            className=" product-displays container parent-container"
            style={{ position: "relative" }}
          >
            <Splide
              options={{
                perPage: 4,
                rewind: true,
                autoplay: true,
                interval: 6000,
                pagination: false,
                gap: "1rem",
                breakpoints: {
                  1200: {
                    perPage: 3,
                    gap: "1rem",
                  },
                  768: {
                    perPage: 2,
                    gap: "0.5rem",
                  },
                  480: {
                    perPage: 2,
                    gap: "0.25rem",
                  },
                },
              }}
              aria-label="customList"
              ref={splideRef}
              onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
            >
              {newArrivals.length > 0 ? (
                newArrivals.map((post) => (
                  <SplideSlide key={post.p_id}>
                    <div
                      className="product-card"
                      onClick={() => productDetails(post.p_id, post.name)}
                    >
                      <div className="product-image-container">
                        <img
                          src={
                            post.image && post.image.length > 0
                              ? `https://lunarsenterprises.com:3008/${post.image}`
                              : Image
                          }
                          alt={post.name}
                          className="default"
                          style={{ borderRadius: 10 }}
                        />
                        <img
                          src={
                            post.image && post.image.length > 0
                              ? `https://lunarsenterprises.com:3008/${post.image[1]?.image}`
                              : Image
                          }
                          alt={post.name}
                          className="hover"
                          style={{ borderRadius: 20 }}
                        />
                        <div className="sale-containerdiscount">
                          <p>-{post.discount}%</p>
                        </div>
                        {post.stock === 0 ? (
                          <div className="sale-containersoldout">
                            <p>Sold out</p>
                          </div>
                        ) : null}
                      </div>
                      <div className="namemain">
                        <div className="product-name">
                          <h6>{post.name}</h6>
                        </div>
                        <div className="product-price-details">
                          <del className="price">
                            <span>Rs</span>
                            {post.price}
                          </del>
                          <div
                            className="discounted-price"
                            style={{ color: "red" }}
                          >
                            <span>Rs</span>
                            {post.discount_price}
                          </div>
                        </div>
                        <div
                          className={`product-namecart cartaddbox bg-danger ${
                            cart.some((item) => item.p_id === post.p_id)
                              ? "selected"
                              : "unselected"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCartClick(post);
                          }}
                        >
                          <p className="centerrr">
                            {cart.some((item) => item.p_id === post.p_id)
                              ? "Remove from Cart"
                              : "Add to Cart"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              ) : (
                <div>
                  <h1>Products not Availiable in New Arrivals</h1>
                </div>
              )}
            </Splide>
          </div>
        </div>
      </div>

      <div className="">
        <h3 className="container mt-3 mb-3 heading">Premium Frames </h3>
        <div className=" container products ">
          <div
            className=" product-displays container parent-container"
            style={{ position: "relative" }}
          >
            <Splide
              options={{
                perPage: 4,
                rewind: true,
                autoplay: true,
                interval: 6000,
                pagination: false,
                gap: "1rem",
                breakpoints: {
                  1200: {
                    perPage: 3,
                    gap: "1rem",
                  },
                  768: {
                    perPage: 2,
                    gap: "0.5rem",
                  },
                  480: {
                    perPage: 2,
                    gap: "0.25rem",
                  },
                },
              }}
              aria-label="customList"
              ref={splideRef}
              onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
            >
              {Premiumlist.length > 0 ? (
                Premiumlist.map((post) => (
                  <SplideSlide key={post.p_id}>
                    <div
                      className="product-card bounce "
                      onClick={() => productDetails(post.p_id)}
                    >
                      <div className="product-image-container">
                        <img
                          src={
                            post.image && post.image.length > 0
                              ? `https://lunarsenterprises.com:3008/${post.image[0]?.image}`
                              : Image
                          }
                          alt={post.name}
                          className="default"
                          style={{ borderRadius: 10 }}
                        />
                        <img
                          src={
                            post.image && post.image.length > 0
                              ? `https://lunarsenterprises.com:3008/${post.image[1]?.image}`
                              : Image
                          }
                          alt={post.name}
                          className="hover"
                          style={{ borderRadius: 20 }}
                        />
                        <div className="sale-containerdiscount">
                          <p>-{post.discount}%</p>
                        </div>
                        {post.stock === 0 ? (
                          <div className="sale-containersoldout">
                            <p>Sold out</p>
                          </div>
                        ) : null}
                      </div>
                      <div className="namemain">
                        <div className="product-name">
                          <h6>{post.name}</h6>
                        </div>
                        <div className="product-price-details">
                          <del className="price">
                            <span>Rs</span>
                            {post.price}
                          </del>
                          <div
                            className="discounted-price"
                            style={{ color: "red" }}
                          >
                            <span>Rs</span>
                            {post.discount_price}
                          </div>
                        </div>
                        <div
                          className={`product-namecart cartaddbox bg-danger ${
                            cart.some((item) => item.p_id === post.p_id)
                              ? "selected"
                              : "unselected"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCartClick(post);
                          }}
                        >
                          <p className="centerrr">
                            {cart.some((item) => item.p_id === post.p_id)
                              ? "Remove from Cart"
                              : "Add to Cart"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              ) : (
                <div>
                  <h1>Products not Availiable in Premium</h1>
                </div>
              )}
            </Splide>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
