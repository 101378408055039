import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./frame.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Image from "../../Assets/noimage.jpg"; // Ensure the fallback image is correctly imported
import Form from "react-bootstrap/Form";
import { useLocation } from 'react-router-dom';


function Frame() {
  const [frames, setFrames] = useState([]);
  const [sortOrder, setSortOrder] = useState(""); // State to track the selected sort order
  const navigate = useNavigate();
  const { size } = useParams();
  const location = useLocation();
  const {frame}=location.state || {};

  useEffect(() => {
    framelisting();
  }, [size,frame]);
  console.log(size);
  console.log(frame );


  const framelisting = async () => {
    try {
      const parameter = { size };
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-products",
        parameter
      );
      setFrames(response.data.list);
    } catch (error) {
      console.error("Error fetching frame list:", error); // Correct error logging
    }
  };

  const handleSortChange = (e) => {
    const value = e.target.value;
    setSortOrder(value);
    
    let sortedFrames = [...frames];
    if (value === "1") {
      // Sort by high to low
      sortedFrames.sort((a, b) => b.discount_price - a.discount_price);
    } else if (value === "2") {
      // Sort by low to high
      sortedFrames.sort((a, b) => a.discount_price - b.discount_price);
    }
    
    setFrames(sortedFrames);
  };

  const buttonClick = (p_id) => {
    navigate(`/product/${p_id}`); // Correct navigation path
  };

  return (
    <div className="frame-container">
      <div className="container">
        <div className="heading-lines row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <h3 className="frame-heading text-center mx-auto">FRAME COLLECTIONS</h3>
            <p style={{color:'grey',textAlign:'center',fontWeight:'bold'}}>{frame}</p>
          </div>
          <div className="col-md-2">
            <Form.Select
              className="framefilterdropdown"
              aria-label="Filter select"
              onChange={handleSortChange} // Trigger sorting on selection
            >
              <option value="">Filter</option>
              <option value="1">High to Low</option>
              <option value="2">Low to High</option>
            </Form.Select>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="container">
        <Row className="justify-content-center">
          {frames?.length > 0 ? (
            frames.map((frame, index) => (
              <Col lg={3} md={4} sm={6} xs={12} className="mb-4" key={index}>
                <Card className="mx-auto product-card">
                  <div className="image-content">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      {frame.stock <= 0 && (
                        <p className="text-left" style={{ backgroundColor: "black", color: "white" }}>
                          Sold Out
                        </p>
                      )}
                      <p className="text-right" style={{ backgroundColor: "red", color: "white" }}>
                        -{frame.discount}%
                      </p>
                    </div>
                    <Card.Img
                      className="framing-card"
                      variant="top"
                      src={
                        frame.image && frame.image.length > 0
                          ? `https://lunarsenterprises.com:3008/${frame.image[0].image}` // Enclose the URL in backticks
                          : Image
                      }
                    />
                  </div>
                  <Card.Body>
                    <div className="mainframe">
                      <div className="frame-name">
                        <h6>{frame.name}</h6>
                      </div>
                    </div>
                    <div className="frame-price-details">
                      <del className="price">
                        <span>Rs</span>
                        {frame.price}
                      </del>
                      <div className="frame-discount-price">
                        <span>Rs</span>
                        {frame.discount_price}
                      </div>
                    </div>
                    <Button
                      style={{ width: "100%", backgroundColor: "#219C90" }}
                      variant="primary"
                      onClick={() => buttonClick(frame.p_id)}
                    >
                      Buy Now
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <div>
              <h1 className="text-center">Products not Available</h1>
            </div>
          )}
        </Row>
      </div>
    </div>
  );
}

export default Frame;
