import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Collection from "./pages/Collection/Collection";
import Checkout from "./pages/Checkout/Checkout";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Terms from "./components/Terms/Terms";
import Refund from "./components/Refund/Refund";
import Privacy from "./components/Privacy/Privacy";
import Scroll from "./components/Scroll";
import {SearchProvider} from "./context/Searchcontext";
import { BannerProvider } from "./context/Banner";
import "./App.scss";
import Thankyou from "./pages/Checkout/Thankyou";
import 'bootstrap/dist/css/bootstrap.min.css';
import StickyBottom from "./components/sticky/stickybottom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Selected from "./pages/Selected-product/Selected";
import { red } from "@mui/material/colors";
import Frame from "./pages/Collection/Frame";
import Mug from "./pages/Collection/Mug";
import Reviews from "./pages/reviews/Reviews";




export default function App() {
  

  return (
    <BannerProvider>
    <SearchProvider>
    <BrowserRouter>
      <Scroll>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/frame/:size" element={<Frame />} />
          <Route path="/mug" element={<Mug />} />
          <Route path="/review" element={<Reviews />} />
          

          <Route
            path="/checkout"
            element={
              <div
                className="container-fluid"
                style={{ background: '#A5DD9B' }}
              >
                <Checkout />
              </div>
            }
          />
          <Route path="/product/:p_id" element={<Selected />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/thankyou" element={<Thankyou />} />
        </Routes>
        <StickyBottom/>
        <Footer />
      </Scroll>
    </BrowserRouter>
    </SearchProvider>
    </BannerProvider>
  );
}
