import React, { useContext, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { BannerContext } from '../../context/Banner';
import './Carausel.scss';
import { useState } from 'react';
import axios from 'axios';
import Image from "../../Assets/noimage.jpg";


function UncontrolledExample() {
  const { banner } = useContext(BannerContext); // Access banner data from context
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    bannerlisting();
  },[]);
  const bannerlisting = async () => {
    try {
      const response = await axios.post("https://lunarsenterprises.com:3008/newlook/list-banner");
      setBanners(response.data.list);
      console.log(response);
    } catch (error) {
      console.log(`Error fetching banner list:`, error);
    }
  };


  if (!Array.isArray(banners) || banners.length === 0) {
    return <div></div>; // Render a loading state while banner is being fetched or if it's not an array
  }

  return (
    <Carousel indicators={false}>
      { 
        banners.map((data) => (
          <Carousel.Item key={data.b_image} interval={3000}>
            <img 
             src={data.b_image ? `https://lunarsenterprises.com:3008/${data.b_image}` : Image}

              alt="" 
              style={{width:"100%"}} 
              id='zero'
            />
          </Carousel.Item>
        ))
      }
    </Carousel>
  );
}

export default UncontrolledExample;
