import React from 'react';
import './Footer.scss';
import { Nav } from 'react-bootstrap';
import Logo from "../../Assets/Logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHouse, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className='Footerstyle'>
      <div className='container'>
        <div className="row justify-content-center align-items-start py-4">
          
          {/* Logo Section */}
          <div className="col-md-4 col-sm-12 text-center mb-3">
            <img src={Logo} alt="Logo" className='logo' />
          </div>
          
          {/* Quick Links Section */}
          <div className="col-md-4 col-sm-12 text-center mb-3">
            <h5>Quick Links</h5>
            <ul className='footer-links'>
              <li><Nav.Link href="/refund">Refund Policy</Nav.Link></li>
              <li><Nav.Link href="/privacy">Privacy Policy</Nav.Link></li>
              <li><Nav.Link href="/terms">Terms of Service</Nav.Link></li>
            </ul>
          </div>

          {/* Contact Information Section */}
          <div className="col-md-4 col-sm-12 text-center mb-3">
            <h5>Contact Us</h5>
            <p>
              <FontAwesomeIcon className="me-2" icon={faHouse} />Manarkkad, Palakkad, Kerala<br />
              <FontAwesomeIcon className="me-2" icon={faEnvelope} />newlook27132@gmail.com<br />
              <FontAwesomeIcon className="me-2" icon={faPhone} />+91 7306079421
            </p>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className='text-center py-3'>
          <p className='powered'>© 2024, Powered by NewLook</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
