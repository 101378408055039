import { useState, useContext, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCartShopping,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { SearchContext } from "../../context/Searchcontext";
import axios from "axios";
import "./Header.scss";
import Logo from "../../Assets/Logo.png";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const Header = () => {
  const [dropdownVisibleId, setDropdownVisibleId] = useState(null);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { setSearchResults } = useContext(SearchContext);
  const [cartCount, setCartCount] = useState(() => {
    return JSON.parse(localStorage.getItem("cart"))?.length || 0;
  });
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = () => {
      setCartCount(JSON.parse(localStorage.getItem("cart"))?.length || 0);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleSearch = async (e) => {
    const searchText = e.target.value;
    if (searchText.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-product",
        { search: searchText }
      );
      setSearchResults(response.data.data);
    } catch (error) {
      console.error("An error occurred while searching:", error);
    }
  };

  const handleDropdownToggle = (id) => {
    setDropdownVisibleId(dropdownVisibleId === id ? null : id);
  };

  const handleMouseEnter = (id) => {
    setDropdownVisibleId(id);
  };

  const handleMouseLeave = () => {
    setDropdownVisibleId(null);
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest(".dropdown-container")) {
      setDropdownVisibleId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [selectedSize, setSelectedSize] = useState(null);
  const handleSizeSelection = async (size,frame) => {
    setSelectedSize(size); // Update selected size
    navigate(`/frame/${size}`,{state:{frame}});
    await framelist(size); // Pass size to framelist
  };

  const framelist = async (size) => {
    try {
      const params = {
        size: size, // Dynamically pass the selected size
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-products",
        params
      );
      // Handle the response here
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching frame list:", error);
    }
  };

  return (
    <>
      <div className="sticky">
        <div className="container-fluid boss-header">
          <div className="navmainstyle">
            <Navbar expand="lg" className="boss-header container header">
              <Navbar.Brand href="/" className="mx-auto">
                <img src={Logo} className="logostyle" alt="logo" />
              </Navbar.Brand>
              <div className="header-icons ml-auto">
                {/* <div className="sideicondiv" onClick={toggleSearch}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="sideicon" />
                </div> */}
                {/* <div className="sideicondiv">
                  <FontAwesomeIcon icon={faUser} className="sideicon" />
                </div> */}
                <div className="sideicondiv" onClick={() => navigate("/cart")}>
                  <FontAwesomeIcon icon={faCartShopping} className="sideicon" />
                  {cartCount > 0 && (
                    <span className="badgestyle">{cartCount}</span>
                  )}
                </div>
              </div>
            </Navbar>
          </div>
        </div>

        <div className="maindivall">
          <div className="row">
            <div className="col-4">
              <div className="dropdown-container hovereffecthead categorysection">
                <a className="headline" href="/">
                  <p className="hovereffectheadsize">All Collections</p>
                </a>
              </div>
            </div>

            <div className="col-4">
              <div
                className="dropdown-container hovereffecthead categorysection"
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
              >
                <a className="headline" onClick={() => handleDropdownToggle(2)}>
                  <p className="hovereffectheadsize">Frame Collections</p>
                </a>
                {dropdownVisibleId === 2 && (
                  <div className="dropdown-content">
                    <p
                      className="sizeframe"
                      onClick={() => handleSizeSelection("3","4x4 Frame")}
                    >
                      4x4 Frame
                    </p>
                    <p
                      className="sizeframe"
                      onClick={() => handleSizeSelection("4","5x5 Frame")}
                    >
                      5x5 Frame
                    </p>
                    <p
                      className="sizeframe"
                      onClick={() => handleSizeSelection("5","6x6 Frame")}
                    >
                      6x6 Frame
                    </p>
                    <p
                      className="sizeframe"
                      onClick={() => handleSizeSelection("6"," 5x7 Frame")}
                    >
                      5x7 Frame
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="col-4">
              <div
                className="dropdown-container hovereffecthead categorysection"
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}
              >
                <a
                  className="headline"
                  href="/mug"
                  onClick={() => handleDropdownToggle(3)}
                >
                  <p className="hovereffectheadsize">Customized Mug</p>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* {isSearchVisible && (
           <Form className="header-searchbox d-flex w-75">
           <Form.Control
             className="searcher"
             type="search"
             placeholder="Search"
             aria-label="Search"
             style={{ borderRadius: "8px" }}
             onChange={handleSearch}
           />
           <Button
             style={{
               width: "80px",
               borderRadius: "8px",
               backgroundColor: "black",
               border: "none",
             }}
           >
             <FontAwesomeIcon icon={faMagnifyingGlass} />
           </Button>
         </Form>
        )} */}
      </div>
    </>
  );
};

export default Header;
